a {
	transition: all 0.2s;
}

img {
	max-width: 100%;
	height: auto;
}

svg.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

.gtf-back-to-top {
	opacity: 0;
	transition: opacity 0.5s;

	&.in {
		opacity: 1;
	}
}

.aa-Panel {
	z-index: 1000;
}

@media (min-width: 768px) {
	.custom-col-stretched-right {
		position: absolute;
		height: 100%;
		top: 0;
		width: 50vw !important;
		right: 50%;
		margin-right: -50vw;
		max-width: none;
	}
}

@media (min-width: 1025px) {
	.custom-col-stretched-right {
		width: 60vw !important;
		right: 60%;
		margin-right: -60vw;
	}
}

.badge {

}

@media (max-width: 480px) {
	.bg-img-cover-center-01 {
		background-image: url('/images/bg-slider-mobile-01.jpg');
	}
	.bg-img-cover-center-02 {
		background-image: url('/images/bg-slider-mobile-02.jpg');
	}
	.bg-img-cover-center-03 {
		background-image: url('/images/bg-slider-mobile-03.jpg');
	}
}

@media (min-width: 481px) and (max-width: 1024px) {
	.bg-img-cover-center-01 {
		background-image: url('/images/bg-slider-01.jpg');
	}
	.bg-img-cover-center-02 {
		background-image: url('/images/bg-slider-02.jpg');
	}
	.bg-img-cover-center-03 {
		background-image: url('/images/bg-slider-03.jpg');
	}
}

@media (min-width: 1025px) {
	.bg-img-cover-center-01 {
		background-image: url('/images/bg-slider-01.jpg');
	}
	.bg-img-cover-center-02 {
		background-image: url('/images/bg-slider-02.jpg');
	}
	.bg-img-cover-center-03 {
		background-image: url('/images/bg-slider-03.jpg');
	}
}

.cog-page__navigation {
	justify-content: center !important;
}

.cog-button {
	min-width: 300px !important;
}

.text-slide-background {
	background-color: #9EA4D3;
	opacity: 0.6;
}

.text-slide-title {
	color: black;
	padding-left: 1rem;
}

.text-slide-description {
	color: black;
	padding-left: 1rem;
	padding-bottom: 1rem;
}