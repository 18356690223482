@import url(https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

// Color system Hex
$primary: #9EA4D3;
$secondary: #EB863D;
$gray: #f8f8f8;

$white: #fff;
$accent: #B88539;
$red: #BC4F38;
$violet: #82588D;
$theme-colors: (
	'white':$white,
	'accent':$accent,
	'red':$red,
	'violet':$violet,
);

// Body
//
// Settings for the `<body>` element.

$body-color: #353E82;

$link-color: $secondary;
$link-hover-color: $primary;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable_shadows: true;

// Components
//
// Define common padding and border radius sizes and more.
$caret-spacing: 0.25rem;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Urbanist', sans-serif;
$font-size-base: 1.375rem;
$line-height-base: 1.6;

$font-size-lg: 1.25rem;
$font-size-sm: 0.75rem;

$line-height-lg: 2;

$font-weight-lighter: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$h1-font-size: 48px;
$h2-font-size: 40px;
$h3-font-size: 34px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 18px;

$headings-font-weight: 600;
$headings-color: #000;
$headings-line-height: 1.3;

$text-muted: #9B9B9B;
$border-color: #EEEEEE;

$border-radius: 3px;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	sm: 546px,
	md: 738px,
	lg: 962px,
	xl: 1200px
);
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1750px
);

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: (
	0: 0,
	1: ($spacer * .3125), //5px
	2: ($spacer * .625), //10px
	3: $spacer, //16px
	4: ($spacer * 1.25), //20px
	5: ($spacer * 1.5), //24px
	6: ($spacer * 1.875), //30px
	7: ($spacer * 2.5), //40px
	8: ($spacer * 3.125), //50px
	9: ($spacer * 3.75), //60px
	10: ($spacer * 4.375), //70px
	11: ($spacer * 5), //80px
	12: ($spacer * 5.625), //90px
	13: ($spacer * 6.25), //100px
	14: ($spacer * 6.875), //110px
	15: ($spacer * 7.5), //120px
	16:($spacer * 9.375), //150px
	17:($spacer * 10.625), //170px
	18:($spacer * 13.75) //220px
);

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-font-weight: 600;

$btn-padding-x-lg: 2.1875rem;

$btn-padding-x: 1.875rem;

$input-font-weight: 600;
$input-bg: #F5F5F5;
$input-color: $secondary;
$input-border-color: #3F4A3C33;
$input-border-width: 1px;
$input-box-shadow: none;
$input-focus-border-color: $secondary;
$input-focus-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

$input-btn-line-height: 1.65;
$input-placeholder-color: #9B9B9B;
$input-btn-font-size: 0.875rem;
$input-btn-padding-y: 0.625rem;

$input-btn-padding-y-sm: 0.47rem;

$input-padding-y: 0.6875rem;
$input-padding-x: 1.25rem;
$input-line-height: 1.6;

$input-font-size: $font-size-base;
$input-font-weight: $font-weight-normal;


$custom-checkbox-indicator-border-radius: 0;
$custom-control-label-color: $secondary;
$custom-control-indicator-border-color: $secondary;
$custom-control-indicator-checked-box-shadow: none;
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-control-indicator-active-box-shadow: none;

$btn-box-shadow: none;
$btn-padding-y: $input-btn-padding-y;
$btn-font-size: 1rem;
$btn-line-height: $input-btn-line-height;


// Nav Tabs
$nav-tabs-link-active-color: $secondary;
$nav-tabs-border-width: 1px;
$nav-tabs-border-radius: 5px;

// Navbar
$navbar-light-color: $secondary;

$navbar-nav-link-padding-x: 1.25rem;

$navbar-dark-color: #fff;
$navbar-dark-hover-color: #fff;
$navbar-dark-active-color: #fff;
$navbar-dark-disabled-color: #f5f5f5;

$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 1rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: #b2b2b2;
$breadcrumb-active-color: #000;
$breadcrumb-font-size: 15px;

$card-border-radius: 0;

$pagination-padding-x: 21px;
$pagination-color: $secondary;
$pagination-border-width: 0;
$pagination-bg: transparent;
$pagination-hover-color: $primary;
$pagination-hover-bg: #F5F5F5;
$pagination-hover-border-color: transparent;
$pagination-active-color: $primary;
$pagination-active-bg: #F5F5F5;
$pagination-active-border-color: transparent;


$dropdown-link-color: #7E7E7E;
$dropdown-link-hover-color: $secondary;

$dropdown-link-active-color: $secondary;
